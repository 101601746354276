<template>
  <div class="super">
    <div class="holder">
      <!-- <AuthNavbar /> -->
      <!-- <div class="logo-div">
            <div class="logo-blue">
              <img src="../assets/images/logo-header.svg" alt="" />
            </div>
            <div class="logo-shelta">
               <img src="../assets/images/logo1.svg" alt="" />
            </div>
          </div> -->
      <div class="logo_holder">
        <img src="../assets/images/logo-yellow.svg" alt="" />
      </div>
      <div class="container welcome-holder">
        <div class="col-md-8 card welcome-child">
          <div class="card-body">
            <div class="">
              <!-- <div class="close-welcome-div">
                <small><i class="fa fa-times close-welcome-icon"></i></small>
              </div> -->
              <div class="welcome-body">
                <p>Welcome</p>
                <p>We just sent you an email to the address: {{ email }}</p>
                <p>Please check your email and click on the link provided</p>
                <img src="../assets/images/good.svg" alt="welcome" />
                <p><span>...</span>We got you covered!</p>
                <div class="resend-verification-btn">
                  <button class="btn">Resend verification</button>
                </div>
                <div class="backtologin-Btn">
                  <button class="btn">
                    <router-link class="login-router" to="/login"
                      >Back to Login</router-link
                    >
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FooterMain2 />
  </div>
</template>

<script>
import FooterMain2 from "../views/FooterMain2";
// import AuthNavbar from "./AuthNavbar";
export default {
  name: "Verification",
  components: {
    // Header,
    FooterMain2
    // AuthNavbar,
  },
  data() {
    return {
      email: JSON.parse(localStorage.getItem("shelta-user")).email
    };
  }
  //  mounted() {
  //    this.email = JSON.parse(localStorage.getItem("shelta-user")).email
  //    console.log("Email", this.email);
  //  }
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$secondary: #ffffff;
$yellow: #ffb100;
$black: #000000;
// $fontFamily: url("https://fonts.googleapis.com/css2?family=Gotham:wght@100&display=swap");
@font-face {
  font-family: "Gotham-bold";
  src: local("Gotham-bold"),
    url("../assets/fonts/shelta_fonts/Gotham\ Bold.otf") format("truetype");
}
@font-face {
  font-family: "Gotham";
  src: local("Gotham"),
    url("../assets/fonts/shelta_fonts/Gotham\ Book.otf") format("truetype");
}
@font-face {
  font-family: "Gotham-medium";
  src: local("Gotham-medium"),
    url("../assets/fonts/shelta_fonts/Gotham\ Medium.otf") format("truetype");
}

.super {
  flex-direction: column;
  display: flex;
  min-height: 100vh;
  position: relative;
}
.holder {
  flex-grow: 1;
  min-height: 100%;
}

.logo_holder {
  margin-top: 3rem;
}
.logo-div {
  position: relative;
}
.logo-blue > img {
  width: 18.3016105417vw;
}
.logo-shelta {
  position: absolute;
  top: 16%;
  left: 45.7%;
}
.logo-shelta > img {
  width: 8.7115666179vw;
}

.welcome-holder {
  padding-top: 5rem;
  padding-bottom: 3rem;
}

.welcome-child {
  background: $primary;
  // width: 441.03px;
  height: auto;
  margin: 0 auto;
  margin-bottom: 2rem;
}
.close-welcome-div {
  display: flex;
  justify-content: flex-end;
  position: relative;
}
.close-welcome-div > small {
  position: absolute;
  top: 1rem;
}
.welcome-body {
  padding-top: 1rem;
}
.close-welcome-icon::before {
  color: $secondary;
  font-size: 1rem;
}
.welcome-body > p:first-child {
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 40px;
  align-items: center;
  text-align: center;
  letter-spacing: -0.05em;
  color: $secondary;
  padding-bottom: 1rem;
}
.welcome-body > p:nth-child(2) {
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  align-items: center;
  text-align: center;
  margin-bottom: 0;
  //   letter-spacing: -0.05em;
  color: $secondary;
}
.welcome-body > p:nth-child(3) {
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  align-items: center;
  text-align: center;
  //   letter-spacing: -0.05em;
  color: $secondary;
}
.welcome-body > p:nth-child(5) {
  font-family: Gotham Ultra;
  font-style: italic;
  font-weight: 800;
  font-size: 30px;
  line-height: 40px;
  align-items: center;
  text-align: center;
  letter-spacing: -0.05em;
  color: $secondary;
  padding-top: 1rem;
}

.resend-verification-btn > button {
  color: $secondary;
  border: 1px solid $secondary;
  font-family: Gotham;
  font-size: 15px;
  font-weight: normal;
}
.backtologin-Btn > button {
  font-family: Gotham;
  color: $secondary;
  font-weight: normal;
  font-size: 13px;
}
.login-router {
  text-decoration: none;
  color: $secondary;
}

@media screen and (max-width: 360px) and (max-height: 640px) and (-webkit-device-pixel-ratio: 2) {
  .logo-div {
    margin: auto;
    height: 2rem;
    position: relative;
    bottom: 1rem;
  }
  .logo-blue > img {
    width: 42%;
  }
  .logo-shelta {
    position: absolute;
    top: 64%;
    left: 34%;
  }
  .logo-shelta > img {
    width: 62%;
  }
}

@media only screen and (max-width: 599px) {
  // .welcome-child {
  //   min-width: 20rem;
  // }
  .logo_holder {
    margin-top: 3rem;
  }
}
</style>
